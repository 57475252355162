@tailwind base;
@tailwind components;
@tailwind utilities;


@keyframes blink {
    0%, 100% { opacity: 0; }
    50% { opacity: 1; }
  }
  
  .typing-cursor {
    animation: blink 0.7s infinite;
    font-weight: bold;
    font-size: 1.2em;
    vertical-align: middle;
    line-height: 1;
  }
  
  .typing {
    transition: all 0.3s ease;
  }

